import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import FeedbackForm from "./FeedbackForm";
import { ColorScheme } from "./Colors";

interface FeedbackButtonProps {
  apiKey: string;
  colors: ColorScheme;
  buttonText?: string;
  formTitle?: string;
  formSubtitle?: string;
  buttonBorderRadius?: string;
  formBorderRadius?: string;
  mergedOptions: any;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({
  apiKey,
  colors,
  buttonText = "Feedback",
  formTitle = "Send Feedback",
  formSubtitle = "We'd love to hear your thoughts!",
  buttonBorderRadius = "9999px",
  formBorderRadius = "0.5rem",
  mergedOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    const feedbackSubmitted = localStorage.getItem("feedbackSubmitted");
    if (feedbackSubmitted === "true") {
      setHasSubmittedFeedback(true);
    }
  }, []);

  const buttonVariants = {
    visible: { y: 0, opacity: 1, scale: 1 },
    hidden: { y: 100, opacity: 0, scale: 0.8 },
  };

  const handleOpenForm = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsOpen(true);
      setIsAnimating(false);
    }, 300);
  };

  const handleCloseForm = () => {
    setIsOpen(false);
  };

  const handleFeedbackSubmit = () => {
    setIsOpen(false);
    setHasSubmittedFeedback(true);
    setShowThankYou(true);
    localStorage.setItem("feedbackSubmitted", "true");
    setTimeout(() => {
      setShowThankYou(false);
    }, 3000);
  };

  return (
    <motion.div
      initial={{ bottom: "20px", right: "20px" }}
      animate={isOpen ? { bottom: "40px", right: "40px" } : {}}
      transition={{ duration: 0.3 }}
      className="fixed z-[9999]"
    >
      <AnimatePresence mode="wait">
        {!isOpen && !hasSubmittedFeedback && (
          <motion.button
            variants={buttonVariants}
            initial="visible"
            exit="hidden"
            animate={isAnimating ? "hidden" : "visible"}
            transition={{ duration: 0.3 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleOpenForm}
            className="text-white font-bold py-2 px-4 shadow-lg transition-colors duration-300 flex items-center space-x-2"
            style={{
              backgroundColor: colors.primary,
              borderRadius: buttonBorderRadius,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clipRule="evenodd"
              />
            </svg>
            <span style={{ color: colors.primaryContent }}>{buttonText}</span>
          </motion.button>
        )}

        {isOpen && (
          <FeedbackForm
            apiKey={apiKey}
            onClose={handleCloseForm}
            onSubmit={handleFeedbackSubmit}
            colors={colors}
            formTitle={formTitle}
            formSubtitle={formSubtitle}
            formBorderRadius={formBorderRadius}
            mergedOptions={mergedOptions}
          />
        )}

        {showThankYou && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-0 right-0 mb-4 mr-4 text-white font-bold py-2 px-4 rounded-full shadow-lg transition-colors duration-300"
            style={{
              backgroundColor: colors.success,
              color: colors.successContent,
            }}
          >
            Thanks for leaving feedback!
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default FeedbackButton;

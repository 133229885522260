import React from "react";
import { createRoot } from "react-dom/client";
import FeedbackButton from "./FeedbackButton";

interface FeedbackButtonOptions {
  apiKey: string;
}

// Create and append the feedback-root div if it doesn't exist
if (!document.getElementById("feedback-root")) {
  const feedbackRoot = document.createElement("div");
  feedbackRoot.id = "feedback-root";
  document.body.appendChild(feedbackRoot);
}

// Function to verify the API key and domain
const verifyApiKeyAndDomain = async (
  apiKey: string
): Promise<{ verified: boolean; config?: FeedbackButtonOptions }> => {
  console.log(window.location.hostname);
  // Check if the current hostname is localhost
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    console.log("FeedbackButton is disabled on localhost");
    return { verified: false };
  }

  try {
    const response = await fetch(`https://tryformbee.com/api/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        domain: window.location.hostname,
      }),
    });

    if (!response.ok) {
      throw new Error("Verification failed");
    }

    const data = await response.json();
    return { verified: data.verified, config: data.config };
  } catch (error) {
    console.error("Error verifying API key:", error);
    return { verified: false };
  }
};

// Expose the FeedbackButton component globally with options
(window as any).renderFeedbackButton = async (
  options: FeedbackButtonOptions
) => {
  const rootElement = document.getElementById("feedback-root");
  if (rootElement) {
    // Verify the API key and domain before rendering
    const { verified, config } = await verifyApiKeyAndDomain(options.apiKey);

    if (!verified) {
      console.error(
        "API key verification failed. FeedbackButton will not be rendered."
      );
      return;
    }

    // Merge the fetched config with the provided options
    const mergedOptions: any = { ...options, ...config };

    // Create shadow DOM
    const shadowRoot = rootElement.attachShadow({ mode: "open" });

    // Create container for React app
    const container = document.createElement("div");
    shadowRoot.appendChild(container);

    // Inject Tailwind styles and Inter font
    const style = document.createElement("style");
    style.textContent = `
     @import url('https://cdn.jsdelivr.net/npm/daisyui@4.12.10/dist/full.min.css');
      @import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
      
      * {
        font-family: 'Inter', sans-serif;
      }
    `;
    shadowRoot.appendChild(style);

    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <FeedbackButton
          apiKey={mergedOptions.apiKey}
          colors={mergedOptions.colors}
          buttonText={mergedOptions.buttonText}
          formTitle={mergedOptions.formTitle}
          formSubtitle={mergedOptions.formSubtitle}
          buttonBorderRadius={mergedOptions.buttonBorderRadius}
          formBorderRadius={mergedOptions.formBorderRadius}
          mergedOptions={mergedOptions}
        />
      </React.StrictMode>
    );
  } else {
    console.error("Feedback root element not found");
  }
};

// Auto-initialize if the script has data attributes
const script = document.currentScript as HTMLScriptElement;
if (script) {
  const options: FeedbackButtonOptions = {
    apiKey: script.dataset.apiKey || "",
  };

  if (options.apiKey) {
    (window as any).renderFeedbackButton(options);
  }
}
